<template>
  <div>
    <div class="vx-row mb-5">
      <div class="vx-col lg:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 mt-5">
        <vx-card card-background="primary">
          <h1 class="text-white">{{explicitMoneyFormat(investment)}}</h1>
          <p class="text-white mt-1 text-lg">Tu inversión</p>
        </vx-card>
      </div>
      <div class="vx-col lg:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 mt-5">
        <vx-card>
          <h1>{{explicitMoneyFormat(monthlyPayment)}}</h1>
          <p class="mt-1 text-lg">Mensualidad ({{paidCount}}/{{totalTerms}})</p>
        </vx-card>
      </div>
      <div class="vx-col lg:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 mt-5">
        <vx-card>
          <h1>{{decimalPercentFormat(anualInterest)}}</h1>
          <p class="mt-1 text-lg">Tasa de interés</p>
        </vx-card>
      </div>
      <div class="vx-col lg:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 mt-5">
        <vx-card>
          <h1>{{yearsTerm}} años</h1>
          <p class="mt-1 text-lg">Plazo</p>
        </vx-card>
      </div>
    </div>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="card-sub-title mb-3">{{currentStatus}}.</p>
          <p v-if="currentEvent">{{ humanDateFormat(currentEvent.created_at) }}: {{ currentEvent.event }} </p>
          <p v-else>Consulta más detalles sobre los eventos de cobranza que han ocurrido en tu proyecto a lo largo del
            plazo del crédito al dar clic en el botón "Eventos de la cobranza".</p>
        </div>
      </div>
      <div class="vx-row mt-6">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <vs-button type="border" class="sm:px-base px-4 mr-2 mb-3" @click="showCashingLogEvents">Eventos de la cobranza</vs-button>
            <vs-button type="border" color="dark" class="sm:px-base px-4 ml-2 mb-3" @click="showPaymentsPlan">Plan de pagos</vs-button>
          </div>
        </div>
      </div>
    </vx-card>

    <vx-card class="mt-5">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="card-sub-title mb-3">El valor de tu proyecto.</p>
          <p>A continuación podrás consultar el desglose de tu inversión al momento, desde las ganancias y la recuperación de tu inversión,
            hasta el monto que aún se encuentra generando rendimientos. Las cifras económicas son expresadas en moneda nacional.</p>
        </div>
      </div>

      <vs-list v-if="portfolio" class="mt-5 px-6">
        <vs-list-header title="Desglose"></vs-list-header>
        <vs-list-item class="ml-5" title="+ Intereses">{{ explicitMoneyFormat(portfolio.applicant_interests) }}</vs-list-item>
        <vs-list-item class="ml-5" title="+ Intereses moratorios">{{ explicitMoneyFormat(portfolio.applicant_moratorium_interests) }}</vs-list-item>
        <vs-list-item class="ml-5" title="+ Penalizaciones">{{ explicitMoneyFormat(portfolio.applicant_penalties) }}</vs-list-item>
        <vs-list-item class="ml-5" title="+ Bonificaciones">{{ explicitMoneyFormat(portfolio.bonus) }}</vs-list-item>
        <vs-list-item class="ml-5" title="+ IVA">{{ explicitMoneyFormat(portfolio.all_earnings_tax) }}</vs-list-item>
        <vs-list-item class="ml-5" title="- Comisiones">{{ explicitMoneyFormat(portfolio.all_fees) }}</vs-list-item>
        <vs-list-item class="ml-5" title="- IVA comisiones">{{ explicitMoneyFormat(portfolio.fee_tax) }}</vs-list-item>
        <vs-list-item class="ml-5" title="- Retenciones IVA">{{ explicitMoneyFormat(portfolio.iva_withholdings) }}</vs-list-item>
        <vs-list-item class="ml-5" title="- Retenciones ISR">{{ explicitMoneyFormat(portfolio.isr_withholdings) }}</vs-list-item>
        <vs-list-item class="ml-5" title="+ Amortizaciones">{{ explicitMoneyFormat(portfolio.amortizations) }}</vs-list-item>
        <vs-list-item class="ml-5" title="+ Recuperaciones">{{ explicitMoneyFormat(portfolio.recoveries) }}</vs-list-item>
        <vs-list-item><p class="bold text-xl">{{ explicitMoneyFormat(portfolio.balance) }}</p></vs-list-item>

        <vs-list-header title="+ Inversión efectiva"></vs-list-header>
        <vs-list-item class="ml-5" title="+ Inversiones">{{ explicitMoneyFormat(portfolio.investments) }}</vs-list-item>
        <vs-list-item class="ml-5" title="- Amortizaciones">{{ explicitMoneyFormat(portfolio.amortizations) }}</vs-list-item>
        <vs-list-item class="ml-5" title="- Recuperaciones">{{ explicitMoneyFormat(portfolio.recoveries) }}</vs-list-item>
        <vs-list-item class="ml-5" title="- Quebrantos">{{ explicitMoneyFormat(portfolio.loan_loss_provision) }}</vs-list-item>
        <vs-list-item v-if="portfolio.shares_amount_sold != 0" class="ml-5" title="- Venta de participación">{{ explicitMoneyFormat(portfolio.shares_amount_sold) }}</vs-list-item>
        <vs-list-item v-if="portfolio.shares_amount_bought != 0" class="ml-5" title="+ Compra de participación">{{ explicitMoneyFormat(portfolio.shares_amount_bought) }}</vs-list-item>
        <vs-list-item v-if="portfolio.shares_amount_transfer_sent != 0" class="ml-5" title="- Participación transferida">{{ explicitMoneyFormat(portfolio.shares_amount_transfer_sent) }}</vs-list-item>
        <vs-list-item v-if="portfolio.shares_amount_transfer_received != 0" class="ml-5" title="+ Participación recibida">{{ explicitMoneyFormat(portfolio.shares_amount_transfer_received) }}</vs-list-item>
        <vs-list-item><p class="bold text-xl">{{ explicitMoneyFormat(portfolio.fixed_investment) }}</p></vs-list-item>

        <vs-list-header title="= Valor del proyecto"></vs-list-header>
        <vs-list-item><p class="bold text-2xl">{{ explicitMoneyFormat(portfolio.total) }}</p></vs-list-item>
      </vs-list>
    </vx-card>

    <!-- Popup para tablas -->
    <vs-popup :active.sync="isPopupActive" :title="alias" class="max-width-popup">
      <payments-plan :project-id="projectId" v-if="currentView === 1" />
      <investor-cashing-event-logs :project-id="projectId" v-if="currentView === 3" />
    </vs-popup>

  </div>
</template>

<script>
import creditStatusesHelper from "@mixins/creditStatusesHelper";
import formatHelper from "@mixins/formatHelper";
import PaymentsPlan from "@components/investor/invested/PaymentsPlan";
import InvestorProjectEvents from "@components/investor/invested/InvestorProjectEvents";
import InvestorCashingEventLogs from "@components/investor/invested/InvestorCashingEventLogs";
import ProjectEvidences from "@components/investor/invested/ProjectEvidences";
import ProjectContracts from "@components/investor/invested/ProjectContracts";
import dateHelper from "@mixins/dateHelper";
export default {
  name: "Investment",
  props: {
    projectData: { type: Object, required: true },
    projectId: { type: [String,Number], required: true },
    alias: { type: [String], required: true },
  },
  data(){
    return {
      currentView: 0,
      portfolio: null,
      estimatedInfo: null,
      cashingEvents: [],
    }
  },
  components: {
    PaymentsPlan,
    InvestorProjectEvents,
    InvestorCashingEventLogs,
    ProjectEvidences,
    ProjectContracts
  },
  mixins: [creditStatusesHelper, formatHelper, dateHelper],
  async mounted(){
    this.showLoading(true)
    await this.getEstimatedPaymentInfo();
    await this.getProjectPortfolio();
    await this.getEventsInfo();
    this.showLoading(false)
  },
  computed: {
    currentStatus(){
      return this.creditStatusName(this.projectData.finance.asset_status, this.projectData.finance.credit_status);
    },
    currentEvent(){
      if(this.cashingEvents.length && this.cashingEvents.length > 0){
        return this.cashingEvents[0];
      }
      else {
        return null;
      }
    },
    currentDescription(){
      if(this.cashingEvents.length && this.cashingEvents.length > 0){
        return this.cashingEvents[0].event;
      }
      else {
        return null;
      }
    },
    isPopupActive: {
      get() {
        return this.$store.state.isProjectDetailPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE", val);
      }
    },
    investment(){
      if(this.portfolio){
        return this.portfolio.investments;
      }
      return 0;
    },
    monthlyPayment(){
      if(this.estimatedInfo){
        return this.estimatedInfo.pagoMensualTotal;
      }
      return 0;
    },
    paidCount(){
      return this.projectData.finance.paid_count;
    },
    totalTerms(){
      return this.projectData.finance.term;
    },
    anualInterest(){
      return this.projectData.finance.anual_interest;
    },
    yearsTerm(){
      return this.projectData.finance.term / 12;
    }
  },
  methods: {
    async getProjectPortfolio()
    {
      try {
        const res = await axios.get(`/api/v2/investor/${this.InvestorId}/getProjectPortfolio/${this.projectId}`);
        this.portfolio = res.data;
      }
      catch (e) {
      }
    },
    async getEstimatedPaymentInfo(){
      try {
        const res = await axios.get(`/api/v2/investor/${this.InvestorId}/getEstimatedPaymentForInvestor/${this.projectId}`);
        this.estimatedInfo = res.data;
      }
      catch (e) {
      }
    },
    async getEventsInfo(){
      try{
        const response = await axios.get(`api/inversionista/inversiones/resumen/${this.projectId}/cashingEventLogs`);
        this.cashingEvents = response.data;
      }
      catch (e) {
      }
    },
    showPaymentsPlan(){
      // se genera log de accion
      let extraParams = {
        project_id: this.projectId,
        alias: this.alias
      };
      this.saveInvestorLogAction("LC10-21", extraParams);
      this.currentView = 1;
      this.$store.commit('TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE', true)
    },
    showProjectEvents(){
      this.currentView = 2;
      this.$store.commit('TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE', true)
    },
    showCashingLogEvents(){
      // se genera log de accion
      let extraParams = {
        project_id: this.projectId,
        alias: this.alias
      };
      this.saveInvestorLogAction("LC10-20", extraParams);
      this.currentView = 3;
      this.$store.commit('TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE', true)
    },
    backToDetail(){
      this.currentView = 0;
    }
  }
}
</script>

<style scoped>

</style>